import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * 基本页面，所以用户都可以访问的，不需要授权
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      }
    ]
  },
  // {
  //   path: '/device',
  //   component: Layout,
  //   redirect: '/device/selectMap',
  //   hidden: true,
  //   children: [
  //     {
  //       path: 'selectMap',
  //       component: () => import('@/views/device/selectMap'),
  //       name: 'selectMap',
  //       meta: { title: 'selectMap', icon: 'user', noCache: true }
  //     }
  //   ]
  // }


]

export const noControlMenus = [

]

/**
 * asyncRoutes
 * 异步路由，有权限的限制，管理界面都在这里
 */
export const asyncRoutes = [

  // {
  //   path: '/device',
  //   component: Layout,
  //   meta: { title: '设备管理', icon: 'lock' },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/device/list'),
  //       name: 'deviceList',
  //       meta: { title: '设备列表', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     },
  //     {
  //       path: 'log',
  //       component: () => import('@/views/device/log'),
  //       name: 'deviceLog',
  //       meta: { title: '设备日志', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     },
  //     {
  //       path: 'cmdGroup',
  //       component: () => import('@/views/device/cmdGroup'),
  //       name: 'deviceCmdGroup',
  //       meta: { title: '指令集', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     }
  //   ]
  // },

  // {
  //   path: '/data-query',
  //   component: Layout,
  //   redirect: '/data-query/page',
  //   noCache: false,
  //   name: 'monitor',
  //   meta: { title: '数据管理', icon: 'lock', roles: ['boss', 'operator', 'admin'] },
  //   children: [
  //     {
  //       path: 'now-data',
  //       component: () => import('@/views/data-query/now-data'),
  //       name: 'now-data',
  //       meta: { title: '实时数据', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     },
  //     {
  //       path: 'history',
  //       component: () => import('@/views/data-query/history'),
  //       name: 'history',
  //       meta: { title: '历史数据', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     }
  //   ]
  // },

  // {
  //   path: '/program-manage',
  //   component: Layout,
  //   redirect: '/program-manage/page',
  //   noCache: false,
  //   // alwaysShow: true, // will always show the root menu
  //   name: 'program-manage',
  //   meta: { title: '固件管理', icon: 'lock', roles: ['boss', 'operator', 'admin'] },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/program-manage/list'),
  //       name: 'list',
  //       meta: {
  //         title: '固件列表', icon: 'eye-open', roles: ['boss', 'operator', 'admin']
  //       }
  //     },
  //     {
  //       path: 'upgrade',
  //       component: () => import('@/views/program-manage/upgrade'),
  //       name: 'upgrade',
  //       meta: { title: '固件升级', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     },
  //     {
  //       path: 'log',
  //       component: () => import('@/views/program-manage/log'),
  //       name: 'upgrade-log',
  //       meta: { title: '升级日志', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     }
  //   ]
  // },

  // {
  //   path: '/group-manage',
  //   component: Layout,
  //   redirect: '/group-manage/page',
  //   noCache: false,
  //   name: 'group-manage',
  //   meta: { title: '分组管理', icon: 'lock', roles: ['boss', 'operator', 'admin'] },
  //   children: [
  //     {
  //       path: 'list', component: () => import('@/views/group-manage/list'),
  //       name: 'list', meta: { title: '分组列表', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     },
  //     {
  //       path: 'info', component: () => import('@/views/group-manage/info'),
  //       name: 'info', meta: { title: '分组信息', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     },
  //     {
  //       path: 'control', component: () => import('@/views/group-manage/control'),
  //       name: 'control', meta: { title: '分组控制', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     }
  //   ]
  // },

  // {
  //   path: '/event-manage',
  //   component: Layout,
  //   redirect: '/event-manage/page',
  //   noCache: false,
  //   name: 'event-manage',
  //   meta: { title: '触发管理', icon: 'lock', roles: ['1', 'operator', 'admin'] },
  //   children: [
  //     {
  //       path: 'list', component: () => import('@/views/event-manage/list'),
  //       name: 'list', meta: { title: '触发规则', icon: 'eye-open', roles: ['1', 'operator', 'admin'] }
  //     },
  //     {
  //       path: 'log', component: () => import('@/views/event-manage/log'),
  //       name: 'log', meta: { title: '触发日志', icon: 'eye-open', roles: ['1', 'operator', 'admin'] }
  //     }
  //   ]
  // },

  // {
  //   path: '/system-manage',
  //   component: Layout,
  //   meta: { title: '系统管理', icon: 'search' },
  //   children: [
  //     {
  //       path: 'index', component: () => import('@/views/operator/index'),
  //       name: 'operator', meta: { title: '操作员管理', icon: 'peoples', roles: ['boss', 'operator', 'admin'] }
  //     },
  //     {
  //       path: 'role', component: () => import('@/views/permission/role'),
  //       name: 'RolePermission', meta: { title: '角色管理', icon: 'peoples', roles: ['admin'] }
  //     }
  //   ]
  // },
  // {
  //   path: '/big-monitor',
  //   component: Layout,
  //   meta: { title: '监控大屏', icon: 'search' },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/big-monitor/index'),
  //       name: 'big-monitor',
  //       meta: { title: '监控大屏', icon: 'peoples' }
  //     }
  //   ]
  // },
  // 内部页面，不需要授权，或者父页面授权就行的
  // {
  //   path: '/data-query',
  //   component: Layout,
  //   redirect: '/data-query/page',
  //   noCache: false,
  //   name: 'monitor',
  //   meta: { title: '数据管理', icon: 'lock', roles: ['boss', 'operator', 'admin'] },
  //   children: [
  //     {
  //       path: 'now-data',
  //       component: () => import('@/views/data-query/now-data'),
  //       name: 'now-data',
  //       meta: { title: '实时数据', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //     },
  // {
  //   path: 'history',
  //   component: () => import('@/views/data-query/history'),
  //   name: 'history',
  //   meta: { title: '历史数据', icon: 'eye-open', roles: ['boss', 'operator', 'admin'] }
  //   }
  // ]
  //   },

  // {
  //   path: '/device/selectMap',
  //   component: Layout,
  //   redirect: '/device/selectMap',
  //   children: [
  //     {
  //       path: 'selectMap',
  //       component: () => import('@/views/device/selectMap'),
  //       name: 'selectMap',
  //       meta: { title: 'selectMap', icon: 'dashboard' }
  //     }
  //   ]
  // },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
