<template>
  <div slot="header" class="clearfix">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header" class="clearfix">
        <span>最新数据</span>
      </div>
      <el-table :data="list">
        <el-table-column label="IMEI" min-width="150px">
          <template slot-scope="{ row }">
            <span>{{ row.imei }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备类型" min-width="150px">
          <template slot-scope="{ row }">
            <span>{{ row.deviceTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="数据项" min-width="150px">
          <template slot-scope="{ row }">
            <span>{{ row.itemName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="数据值" min-width="150px">
          <template slot-scope="{ row }">
            <span>{{ row.value }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上报时间" width="160px" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.createdAt | parseTime("{y}-{m}-{d} {h}:{i}:{s}") }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

  </div>
</template>

<script>
import { getDeviceHisDataList } from '@/api/device'

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        success: 'success',
        pending: 'danger'
      }
      return statusMap[status]
    },
    orderNoFilter(str) {
      return str.substring(0, 30)
    }
  },
  data() {
    return {
      list: null
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getDeviceHisDataList({ page: 1, limit: 10 }).then(response => {
        this.list = response.data.items
      })
    }
  }
}
</script>
