<template>
  <div class="all">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header" class="clearfix">
        <span>设备类型统计</span>
      </div>
      <div id="main2" :class="className" :style="{height:height,width:width}" />
    </el-card>
  </div>
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import { getDeviceTypeStatData } from '@/api/device'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      getDeviceTypeStatData().then(res => {
        const seriesData = []
        for (const item of res.data) {
          seriesData.push({ value: item.statCount, name: item.deviceTypeName || '其他' })
        }
        this.initChart(seriesData)
      })
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(seriesData) {
      this.chart = echarts.init(document.getElementById('main2'), 'macarons')

      this.chart.setOption({
        // title: {
        //   text: '设备类型统计',
        //   textStyle: {
        //     fontSize: 16
        //   }
        // },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },

        legend: {
          left: 'center',
          bottom: '10'
        },
        series: [
          {
            name: '设备数量',
            type: 'pie',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '38%'],
            // data: [
            //   { value: 320, name: '气体检测一' },
            //   { value: 240, name: 'Technology' },
            //   { value: 149, name: 'Forex' },
            //   { value: 100, name: 'Gold' },
            //   { value: 59, name: 'Forecasts' }
            // ],
            data: seriesData,
            animationEasing: 'cubicInOut',
            animationDuration: 2600
          }
        ]
      })
    }
  }
}
</script>
