import request from '@/utils/request'

/**
 * 获取操作员有权限的菜单
 * @returns array
 */
export function getRoutes() {
  return request({
    url: '/v1/my/menus',
    method: 'post'
  })
}

/**
 * 获取所有的菜单，仅在权限设置时使用
 * @returns array
 */
export function getAllMenus() {
  return request({
    url: '/v1/all/menus',
    method: 'post'
  })
}

export function getRoles(data) {
  return request({
    url: '/v1/get/role/list',
    method: 'post',
    data
  })
}

export function getAllRole(data) {
  return request({
    url: '/v1/get/all/role',
    method: 'post',
    data
  })
}

export function addRole(data) {
  return request({
    url: '/v1/create/role',
    method: 'post',
    data
  })
}

export function updateRole(data) {
  return request({
    url: `/v1/update/role`,
    method: 'post',
    data
  })
}

export function deleteRole(data) {
  return request({
    url: '/v1/delete/role',
    method: 'post',
    data
  })
}
