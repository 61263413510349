import { asyncRoutes, constantRoutes } from '@/router'
import Layout from '@/layout'
import { getRoutes } from '@/api/role'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  // 没有roles时认为是不限制权限
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (tmp.id == 11) {
      // 设备管理
      tmp.children.push(
        {
          "id": 1199,
          "path": "selectMap",
          "component": "device/selectMap",
          "name": "selectMap",
          "hidden": true,
          "meta": {
            "title": "更新设备位置",
          }
        }
      )
    }
    if (hasPermission(roles, tmp)) {
      const component = tmp.component
      if (route.component) {
        if (component == 'Layout') {
          tmp.component = Layout
        } else {
          // 接口组件字符串转换成组件对象
          tmp.component = (resolve) => require([`@/views/${component}`], resolve)
        }
        if (tmp.children) {
          tmp.children = filterAsyncRoutes(tmp.children, roles)
        }
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      // 请求后台数据替换src/router/index.js的asyncRoutes异步路由 获取操作员有权限的菜单
      getRoutes().then(response => {
        // filterAsyncRoutes方法作权限过滤和数据转换，roles为登录用户角色ID集合，如：[1,2]
        // console.log(JSON.stringify(response.data))
        const accessedRoutes = filterAsyncRoutes(response.data, roles)
        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      })

      // let accessedRoutes
      // console.log('generateRoutes', asyncRoutes)
      // // admin显示所有权限
      // // if (roles.includes('admin')) {
      // //   accessedRoutes = asyncRoutes || []
      // // } else {
      // //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // // }
      // const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // commit('SET_ROUTES', accessedRoutes)
      // resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
