var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group", {
        on: { handleSetLineChartData: _vm.handleSetLineChartData },
      }),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 8 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("raddar-chart")],
              1
            ),
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 8 } }, [
            _c("div", { staticClass: "chart-wrapper" }, [_c("pie-chart")], 1),
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 8 } }, [
            _c("div", { staticClass: "chart-wrapper" }, [_c("bar-chart")], 1),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 0 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "padding-right": "8px", "margin-bottom": "30px" },
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 12 },
                xl: { span: 12 },
              },
            },
            [_c("transaction-table")],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-bottom": "30px" },
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 12 },
                xl: { span: 12 },
              },
            },
            [_c("last-collect-data-table")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }