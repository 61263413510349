import request from '@/utils/request'

/**
 * 获取所有数据列表，分页
 * @param {*} query
 */
export function getEventRuleList(data) {
  return request({
    url: '/v1/get/event/rule/list',
    method: 'post',
    data
  })
}

export function createEventRule(data) {
  return request({
    url: '/v1/create/event/rule',
    method: 'post',
    data
  })
}

export function updateEventRule(data) {
  return request({
    url: '/v1/update/event/rule',
    method: 'post',
    data
  })
}

export function deleteEventRule(data) {
  return request({
    url: '/v1/delete/event/rule',
    method: 'post',
    data
  })
}
export function getRuleItem(data) {
  return request({
    url: '/v1/get/event/rule/item',
    method: 'post',
    data
  })
}
export function getNeedProEventCount(data) {
  return request({
    url: '/v1/get/need/pro/event/count',
    method: 'post',
    data
  })
}

/**
 * 更新触发日志为已处理
 * @param {*} data
 * @returns
 */
export function updateEventProcessed(data) {
  return request({
    url: '/v1/update/event/processed',
    method: 'post',
    data
  })
}
/**
 * 启用禁用触发规则
 * @param {*} data
 * @returns
 */
export function updateEventRuleStatus(data) {
  return request({
    url: '/v1/update/event/rule/status',
    method: 'post',
    data
  })
}

