import { login, logout, getInfo, loginByWxCode, loginByDingdingCode } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  weChatOpenid: '',
  dingdingOpenid: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // user login
  login({ commit }, loginInfo) {
    return new Promise((resolve, reject) => {
      login({ ...loginInfo }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 根据微信code，后端获取openid，根据openid自动登录
   * @param {*} param0
   * @param {*} code
   * @returns
   */
  loginByWxCode({ commit }, code) {
    console.log('loginByWxCode', code)
    return new Promise((resolve, reject) => {
      loginByWxCode({ code }).then(response => {
        const { data } = response
        if (data.token) {
          commit('SET_TOKEN', data.token)
          setToken(data.token)
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 根据钉钉code，后端获取openid，根据openid自动登录
   * @param {*} param0
   * @param {*} code
   * @returns
   */
  loginByDingdingCode({ commit }, code) {
    // console.log('loginByDdCode', code)
    return new Promise((resolve, reject) => {
      loginByDingdingCode({ code }).then(response => {
        const { data } = response
        if (data.token) {
          commit('SET_TOKEN', data.token)
          setToken(data.token)
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo({ token: state.token }).then(response => {
        const { data } = response
        console.log('getInfo', data)

        if (!data) {
          reject('登录出错，请重新登录')
        }

        const { roles, name, avatar, introduction, menus } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('用户权限为空')
        }

        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_INTRODUCTION', introduction)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
